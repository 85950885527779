import React, { useEffect } from 'react';
 import htmll from "./image/html.png";
 import sassl from "./image/sass.png";
 import cssl from "./image/css.png";
 import jsl from "./image/js.png";
 import reactl from "./image/react.png";
 import cl from "./image/c++.png";
import './style.scss'
function About() {
    useEffect(()=>{
        document.title = `About me`;
    });
    let proglan = ["html", "sass" , "css", "js" , "react", "c"];
    return (  
        <div className='a'>
                <h1 className='a-title'> ABOUT mE</h1>
                <h3>Hello ! My full name is <span>Murzayev Javohir</span>. I am 15. <br/>
                I am from Uzbekistan, <span>Navai</span>. So I started learning Frontend development since 2020 years from <span>IT center</span>.</h3>
            <p>Why Front end ?</p>
            <p>Because, I like build web sites, web apps without Frontend and I enjoy my work</p>
            <div className='prlanicons'>
                <img src={htmll} className='prlanicons-ico' />
                <img src={sassl} className='prlanicons-ico' />
                <img src={cssl} className='prlanicons-ico' />
                <img src={jsl} className='prlanicons-ico' />
                <img src={reactl} className='prlanicons-ico' />
                <img src={cl} className='prlanicons-ico' />
            </div>
        </div>
    );
}

export default About;
