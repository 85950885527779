import React ,{ useEffect } from 'react';
import './main.scss';
import logotipme from '../image/logo_transparent.png';
import ppmusic from '../image/ppmusic.jpg';
import imageSearcherApp from '../image/imageSearcherApp.jpg';
import calcImage from '../image/calc.jpg';
import mySchool from '../image/myschool.jpg';

function Portifolio() {
    useEffect(()=> {
        document.title= 'Portifolio';
    })
    return (
        <div className='p'>
            <div className='p-pws'>
                <img src = {logotipme} />
                <span className='p-pws-word'>
                    <h1>My PWS</h1>
                    <h3>My Personal Web Site.</h3>
                    <h2>Javohir.original</h2>
                    <br/>
                    <a href='/'>Go to original</a>
                </span>
            </div>
            <div className='p-pws'>
                <img src = {ppmusic} />
                <span className='p-pws-word'>
                    <h1>My music player app</h1>
                    <h3>My Portifolio  Web Site.</h3>
                    <h2>ppmusic.vercel.app</h2>
                    <br/>
                    <a href='https://ppmusic.vercel.app/' target="_blank">Go to original</a>
                </span>
            </div>  
            <div className='p-pws'>
                <img src = {imageSearcherApp} />
                <span className='p-pws-word'>
                    <h1>My Image Searcher App</h1>
                    <h3>My Portifolio  Web Site.</h3>
                    <h2>image-searcher-app.vercel.app</h2>
                    <br/>
                    <a href='https://image-searcher-app.vercel.app/' target="_blank">Go to original</a>
                </span>
            </div>
            <div className='p-pws'>
                <img src = {calcImage} />
                <span className='p-pws-word'>
                    <h1>My Calculator App</h1>
                    <h3>My Portifolio  Web Site.</h3>
                    <h2>appcalc.vercel.app</h2>
                    <br/>
                    <a href='https://appcalc.vercel.app/' target="_blank">Go to original</a>
                </span>
            </div>
            <div className='p-pws'>
                <img src = {mySchool} />
                <span className='p-pws-word'>
                    <h1>My School web site</h1>
                    <h3>My Portifolio  Web Site.</h3>
                    <h2>myschool22.vercel.app</h2>
                    <br/>
                    <a href='https://myschool22.vercel.app/' target="_blank">Go to original</a>
                </span>
            </div>
        </div>
    );
}

export default Portifolio;