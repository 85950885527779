import React, { useEffect } from 'react';
import mycomp from '../image/my-comp2.jpg';
import './main.scss'

function Setup() {

    useEffect(()=>{
          document.title = `Setup`;
      });
    return (
    <div className='s'>
        <div className='s-one'>
            <img src={mycomp} />
        </div>
        <div className='s-two'>
            <h2>Hp Laptop</h2>
            <h3>7KFKG0H</h3>
            <ul>
                <li>    <span>RAM</span> : 4 gb </li>
                <li>    <span>ROM</span> : HDD 500 gb </li>
                <li>    <span>OS</span> : Windows 10 <span>(</span>x64<span>)</span> </li>
            </ul>
            <br/>
            <h2>KEYBOARD</h2>
            <h3>DIDEA keyboard</h3>
        </div>
    </div>
    );
}

export default Setup;
