import React, { useEffect } from 'react';
import mylogo from '../image/iam1.jpg';
import './Home.scss';


function Home() {
  useEffect(()=>{
    document.title = `Javohir Original </>`;
});
  return (
    <div className='home'>
        <div className='one'>
            <img src={mylogo} alt= "This is me"/>
            <div className='jobname'>
                    <p>Front end <span>Dev</span>eloper.</p>
                    <p><span>Web</span> Designer.</p>
                    <p><span>Photo</span>grapher.</p>
            </div>
            <div className='square'></div>
        </div>
        <div className='two'>
            <span>
            <h3>Hello !</h3>
            <h2><pre>  My name is :</pre></h2>
            <h1><span>M</span>urzayev <span>J</span>avohir.</h1>
            {/* <div className=''></div> */}
            </span>
            
        </div>
    </div>
  );
}

export default Home;
