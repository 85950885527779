import React from 'react';
// import Nav from './Nav/Nav';
import Home from './Home/Home';
import Loading from './Loading';
import About from './About/About';
import { BrowserRouter as Router, Routes, Route ,Link } from 'react-router-dom';
import logotip from './image/logo_transparent.png';
import './style.scss';
import './animText.scss';
import Setup from './Setup/Setup';
import Portifolio from './Portifolio/Portifolio';
import Contact from './Contact/Contact';
import menubtn from './image/menu.png';
import unmenubtn from './image/cros.png';



function App() {
  return (
    <>
    <span className='menu-icons'>
      <img src ={menubtn} id='menu' onClick={()=>{
        const menubar = document.getElementById("navBar");
        const unmenu = document.getElementById("unmenu");
        const menu = document.getElementById("menu");
        menubar.style.display = "block";
        menubar.style.display = "grid";
        menu.style.display = `none`;
        unmenu.style.display = `block`;
      }}/>
      <img src ={unmenubtn} id='unmenu' onClick={()=>{
        const menubar = document.getElementById("navBar");
        const unmenu = document.getElementById("unmenu");
        const menu = document.getElementById("menu");
        menubar.style.display = "none";
        unmenu.style.display = `none`;
        menu.style.display = `block`;
      }} />
    </span> 
    <Router>
        <nav className='nav'>
            <div>
                <img src={logotip} />
                <span> <Link to ='/'>Javohir.org</Link></span>
            </div>
            <ul id='navBar'>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/about'>About Me</Link></li>
                <li><Link to='/setup'>Setup</Link></li>
                <li><Link to='/portifolio'>Portifolio</Link></li>
                <li><Link to='/contact'>Contact</Link></li>            
            </ul>
        </nav>
        <Loading/>
    <Routes>
        <Route path="/" element = {<Home/>}/>
        <Route path="/about" element = {<About/>}/>
        <Route path="/setup" element = {<Setup/>}/>
        <Route path="/portifolio" element = {<Portifolio/>}/>
        <Route path="/contact" element = {<Contact/>}/>
      </Routes>
    </Router>
  </>
  );
}

export default App;
