import React ,{ useEffect } from 'react';
import './main.scss';
import iTelegram from '../image/telegram.png';
import iInstagram from '../image/instagram.png';
import iYoutube from '../image/youtube.png';

function Contact() {
    useEffect(()=> {
        document.title= 'Contact';
    })
    return (
        <div className='c'>
            <div className='c-one'>
                <h1><span>C</span>ontact</h1>
                <h3>Send me your </h3>
                <h2>Comments</h2>
                
                <div className='c-follow'>
                    <h2><span>Follow me</span> &mdash;&gt;</h2>
                    <a href='https://t.me/JavohirOriginal' target="_blank"><img src ={iTelegram} /></a>
                    <a href='https://www.instagram.com/devjavohir' target="_blank"><img src ={iInstagram} /></a>
                    <a href='https://youtube.com/c/JavohirHakimov' target="_blank"><img src ={iYoutube} /></a>
                </div>
            </div>
            <div className='c-two'>
                <div>
                    <span>Enter your username:</span>
                    <input type='firstname' placeholder='Username' />
                </div>
                <div>
                    <span>Enter your Email:</span>
                    <input type='email' placeholder='Email' />
                </div>
                <div>
                    <span>Enter your Country:</span>
                    <input  placeholder='Countrty' />
                </div>
                <div>
                    <span>Enter your Massage:</span>
                    <textarea name='massage' contentEditable="0" placeholder='Massage'/>
                </div>
                <button type="submit">Submit</button>
            </div>
        </div>
    );
}

export default Contact;