import React from 'react';
import './loading.scss';

function Loading() {
    return (
        <div className="load">
            <div className="load-box-1"></div>
            <div className="load-box-2"></div>
            <div className="load-box-3"></div>
        </div>
    );
}
window.addEventListener("load",() =>{
    const load = document.querySelector(".load");
    load.style.display = 'none';
})
export default Loading;